/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 25.03.24.09.38
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProjectCreateRequest,
  ProjectCreateResponse,
  ProjectListResponse,
  ProjectResponse,
} from '../models';
import {
    ProjectCreateRequestFromJSON,
    ProjectCreateRequestToJSON,
    ProjectCreateResponseFromJSON,
    ProjectCreateResponseToJSON,
    ProjectListResponseFromJSON,
    ProjectListResponseToJSON,
    ProjectResponseFromJSON,
    ProjectResponseToJSON,
} from '../models';

export interface ApiProjectCreateRequest {
    projectCreateRequest: ProjectCreateRequest;
}

export interface ApiProjectDestroyRequest {
    id: number;
}

export interface ApiProjectRetrieveRequest {
    id: number;
}

export interface ApiProjectUpdateRequest {
    id: number;
    projectResponse: ProjectResponse;
}

/**
 * 
 */
export class ProjectApi extends runtime.BaseAPI {

    /**
     * Endpoint for creating a project
     */
    async apiProjectCreateRaw(requestParameters: ApiProjectCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectCreateResponse>> {
        if (requestParameters.projectCreateRequest === null || requestParameters.projectCreateRequest === undefined) {
            throw new runtime.RequiredError('projectCreateRequest','Required parameter requestParameters.projectCreateRequest was null or undefined when calling apiProjectCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/project/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectCreateRequestToJSON(requestParameters.projectCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectCreateResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for creating a project
     */
    async apiProjectCreate(requestParameters: ApiProjectCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectCreateResponse> {
        const response = await this.apiProjectCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for deleting a project
     */
    async apiProjectDestroyRaw(requestParameters: ApiProjectDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiProjectDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/project/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for deleting a project
     */
    async apiProjectDestroy(requestParameters: ApiProjectDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiProjectDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for receiving a list of projects the user has access to
     */
    async apiProjectListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProjectListResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/project/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectListResponseFromJSON));
    }

    /**
     * Endpoint for receiving a list of projects the user has access to
     */
    async apiProjectList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProjectListResponse>> {
        const response = await this.apiProjectListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for receiving a project
     */
    async apiProjectRetrieveRaw(requestParameters: ApiProjectRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiProjectRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/project/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for receiving a project
     */
    async apiProjectRetrieve(requestParameters: ApiProjectRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectResponse> {
        const response = await this.apiProjectRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for updating a project
     */
    async apiProjectUpdateRaw(requestParameters: ApiProjectUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiProjectUpdate.');
        }

        if (requestParameters.projectResponse === null || requestParameters.projectResponse === undefined) {
            throw new runtime.RequiredError('projectResponse','Required parameter requestParameters.projectResponse was null or undefined when calling apiProjectUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/project/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectResponseToJSON(requestParameters.projectResponse),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for updating a project
     */
    async apiProjectUpdate(requestParameters: ApiProjectUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectResponse> {
        const response = await this.apiProjectUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
