import React from "react";
import { FaPlus } from "react-icons/fa";
import { GoProjectRoadmap } from "react-icons/go";
import { GrUserAdmin } from "react-icons/gr";
import {
  MdAddLocationAlt,
  MdEdit,
  MdLogout,
  MdOutlineFeed,
  MdOutlinePermContactCalendar,
} from "react-icons/md";
import { RiRoadMapLine } from "react-icons/ri";
import { AppStateHandler } from "src/AppStateHandler";
import { AppStateType, ScreenState } from "src/Types";
import { ScenarioTypeEnum } from "src/client/lib/models";

import MenuTitle from "./MenuTitle";
import "./ProjectBar.css";
import EditScenarioPopUp from "./EditScenarioPopUp";

interface ProjectBarProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class ProjectBar extends React.Component<ProjectBarProps> {
  render() {
    return (
      <>
        {(() => {
          if (this.props.state.editScenario.editScenarioPopUpVisible) {
            return (
              <EditScenarioPopUp
                changeName={this.props.state.editScenario.name}
                updateName={(newName) => {
                  this.props.stateHandler.editScenario(newName,
                    this.props.updateState);
                }}
                onSave={() => {
                  this.props.stateHandler.updatePartialScenario(this.props.state.editScenario.name ?? "",
                    this.props.updateState
                  );
                }
                }
                onClose={() => {
                  this.props.stateHandler.closePopUp((newState) => {
                    this.props.state.editScenario.editScenarioPopUpVisible = false;
                    this.props.updateState(newState);
                  });
                }} />
            );
          }
        })()}

        <div id="ProjectBar">
          <div className="SmallMenu-container">
            <div className="SmallMenu-logo"></div>
            <div className="SmallMenu-buttons">
              <button
                className="SmallMenu-button"
                onClick={() => {
                  this.props.stateHandler.changeScreen(
                    ScreenState.CustomAnalysisLayersScreen,
                    this.props.updateState
                  );
                }}
                title="Bestemmingen"
              >
                <MdAddLocationAlt />
              </button>
              {this.props.state.current_user.is_staff ? (
                <button
                  className="SmallMenu-button"
                  onClick={() => {
                    this.props.stateHandler.changeScreen(
                      ScreenState.AdminScreen,
                      this.props.updateState
                    );
                  }}
                  title="Admin"
                >
                  <GrUserAdmin />
                </button>
              ) : (
                <button
                  className="SmallMenu-button"
                  onClick={() => {
                    this.props.stateHandler.changeScreen(
                      ScreenState.DocsScreen,
                      this.props.updateState
                    );
                  }}
                  title="Handleiding"
                >
                  <MdOutlineFeed />
                </button>
              )}
              <button
                className="SmallMenu-button"
                onClick={() => {
                  this.props.stateHandler.changeScreen(
                    ScreenState.ContactScreen,
                    this.props.updateState
                  );

                }}
                title="Contact"
              >
                <MdOutlinePermContactCalendar />
              </button>
              <button
                className="SmallMenu-button"
                onClick={() => {
                  this.props.stateHandler.logout(this.props.updateState);
                }}
                title="Uitloggen"
              >
                <MdLogout />
              </button>
            </div>
          </div>

          <MenuTitle
            title="Projecten"
            onClick={() => {
              this.props.stateHandler.changeScreen(
                ScreenState.NewProject,
                this.props.updateState
              );
            }}
            button={true}
            button_content={<FaPlus size={25} />}
            sortingButton={true}
            filteringButton={true}
            sortingOption={this.props.state.sortingOption}
            filteringOption={this.props.state.filteringOption}
            onSortingOptionSelect={(option) => { this.props.stateHandler.changeSortingOption(this.props.updateState, option); }}
            onFilteringOptionSelect={(option) => { this.props.stateHandler.changeFilteringOption(this.props.updateState, option); }}
            transparentButtonBackground={false}
            no_margin={true}
          />

          <div id="ProjectBar-ListHolder">
            <ul id="ProjectBar-List">
              {this.props.state.projects.map((project) =>
                project.scenarioType === this.props.state.filteringOption || this.props.state.filteringOption === "ALL_PROJECTS" ? (
                  <li key={project.id} className={"ProjectBar-ProjectItem"}>
                    <span
                      className={
                        project.id === this.props.state.selectedProject
                          ? "ProjectBar-ProjectName selected"
                          : "ProjectBar-ProjectName"
                      }
                      onClick={() => {
                        this.props.stateHandler.changeSelectedProject(
                          project.id,
                          this.props.updateState
                        );
                      }}
                    >
                      <GoProjectRoadmap /> {project.name}
                    </span>

                    <ul className="ProjectBar-Sublist">
                      {project.scenarioList.map((scenario) => (
                        <div key={scenario.id} className="ProjectListContainer">
                          <li
                            className={
                              scenario.id === this.props.state.selectedScenario
                                ? "selected"
                                : ""
                            }
                            onClick={() => {
                              this.props.stateHandler.changeSelectedScenario(
                                project.id,
                                scenario.id,
                                this.props.updateState
                              );
                            }}
                          >
                            <RiRoadMapLine />
                            &nbsp;&nbsp;{scenario.name}
                            {scenario.name !== "Huidige situatie" ? (
                              <MdEdit id="EditButton"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  this.props.state.editScenario.id = scenario.id;
                                  this.props.state.editScenario.projectId = project.id;
                                  this.props.state.editScenario.name = scenario.name;
                                  this.props.state.editScenario.editScenarioPopUpVisible = true;
                                  this.props.updateState(this.props.state);
                                }} />)
                              : null}
                          </li>
                        </div>
                      ))}
                      {(() => {
                        if (
                          project.scenarioList.length > 0 &&
                          project.scenarioType !==
                          ScenarioTypeEnum.TravelTimeIsochrones
                        ) {
                          return (
                            <li
                              onClick={() => {
                                this.props.stateHandler.projectBarCreateNewScenario(
                                  project.id,
                                  this.props.updateState
                                );
                              }}
                            >
                              <FaPlus /> &nbsp;Nieuw scenario
                            </li>
                          );
                        }
                      })()}
                    </ul>
                  </li>
                ) : null
              )}
            </ul>
          </div>
        </div>
      </>
    );
  }
}
