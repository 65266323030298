/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 25.03.24.09.38
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Destination } from './Destination';
import {
    DestinationFromJSON,
    DestinationFromJSONTyped,
    DestinationToJSON,
} from './Destination';
import type { TypeEnum } from './TypeEnum';
import {
    TypeEnumFromJSON,
    TypeEnumFromJSONTyped,
    TypeEnumToJSON,
} from './TypeEnum';

/**
 * 
 * @export
 * @interface DestinationGroupListResponse
 */
export interface DestinationGroupListResponse {
    /**
     * 
     * @type {number}
     * @memberof DestinationGroupListResponse
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof DestinationGroupListResponse
     */
    name: string;
    /**
     * 
     * @type {TypeEnum}
     * @memberof DestinationGroupListResponse
     */
    type: TypeEnum;
    /**
     * 
     * @type {Array<Destination>}
     * @memberof DestinationGroupListResponse
     */
    destinations: Array<Destination>;
    /**
     * 
     * @type {boolean}
     * @memberof DestinationGroupListResponse
     */
    _public: boolean;
}

/**
 * Check if a given object implements the DestinationGroupListResponse interface.
 */
export function instanceOfDestinationGroupListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "destinations" in value;
    isInstance = isInstance && "_public" in value;

    return isInstance;
}

export function DestinationGroupListResponseFromJSON(json: any): DestinationGroupListResponse {
    return DestinationGroupListResponseFromJSONTyped(json, false);
}

export function DestinationGroupListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DestinationGroupListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': TypeEnumFromJSON(json['type']),
        'destinations': ((json['destinations'] as Array<any>).map(DestinationFromJSON)),
        '_public': json['public'],
    };
}

export function DestinationGroupListResponseToJSON(value?: DestinationGroupListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': TypeEnumToJSON(value.type),
        'destinations': ((value.destinations as Array<any>).map(DestinationToJSON)),
        'public': value._public,
    };
}

