/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 25.03.24.09.38
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `TRAVEL_TIME_ISOCHRONES` - Travel Time Isochrones
 * * `BICYCLE_ORIENTED_DEVELOPMENT` - Bicycle Oriented Development
 * * `ORIGIN_DESTINATION` - Origin Destination
 * @export
 */
export const ScenarioTypeEnum = {
    TravelTimeIsochrones: 'TRAVEL_TIME_ISOCHRONES',
    BicycleOrientedDevelopment: 'BICYCLE_ORIENTED_DEVELOPMENT',
    OriginDestination: 'ORIGIN_DESTINATION'
} as const;
export type ScenarioTypeEnum = typeof ScenarioTypeEnum[keyof typeof ScenarioTypeEnum];


export function ScenarioTypeEnumFromJSON(json: any): ScenarioTypeEnum {
    return ScenarioTypeEnumFromJSONTyped(json, false);
}

export function ScenarioTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScenarioTypeEnum {
    return json as ScenarioTypeEnum;
}

export function ScenarioTypeEnumToJSON(value?: ScenarioTypeEnum | null): any {
    return value as any;
}

