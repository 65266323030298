import React from "react";
import { BiNetworkChart, BiSolidHomeAlt2 } from "react-icons/bi";
import { GoXCircleFill } from "react-icons/go";
import { RiRoadMapLine } from "react-icons/ri";
import { TbCheck, TbHistory, TbReload } from "react-icons/tb";
import MainButton from "src/components/MainButton";
import MenuTitle from "src/components/MenuTitle";
import SectionTitle from "src/components/SectionTitle";
import { AppStateHandler } from "../../AppStateHandler";
import { CategoryEnum, ScenarioTypeEnum } from "../../client/lib/models";
import { AppStateType, PopUpState, ScreenState } from "../../Types";
import "./ScenarioResultScreen.css";
import ResultItem from "./ResultItem";
import ResultItemSideBar from "./ResultItemSideBar";

interface ScenarioResultScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class ScenarioResultScreen extends React.Component<ScenarioResultScreenProps> {
  render() {
    return (
      <div id="ScenarioResultScreen">
        <div className="top-bar">
          <ul>
            <li>
              <MenuTitle
                title={
                  (this.props.state.scenarioScreen.name
                    ? this.props.state.scenarioScreen.name
                    : "") +
                  " | " +
                  this.props.state.scenarioScreen.scenarioTypeInDutch
                }
                icon={<RiRoadMapLine size={19} />}
              />
            </li>

            {(() => {
              if (!this.props.state.scenarioScreen.calculationFinished) {
                return (
                  <>
                    <li>
                      <div className="spinner">
                        <div className="pin"></div>
                      </div>
                    </li>
                    <li>
                      <MainButton
                        onClick={() => {
                          this.props.stateHandler.killScenario(
                            this.props.updateState
                          );
                        }}
                        icon={<TbReload size={12} />}
                        className="MainBtn Danger"
                      >
                        berekening beëindigen
                      </MainButton>
                    </li>
                  </>
                );
              } else {
                if (this.props.state.scenarioScreen.upToDate) {
                  return (
                    <li>
                      <div className="upToDate">
                        <TbCheck />
                      </div>
                    </li>
                  );
                } else {
                  return (
                    <li>
                      <div className="upToDate outOfDate">
                        <TbHistory />
                      </div>
                    </li>
                  );
                }
              }
            })()}

            {(() => {
              if (
                this.props.state.scenarioScreen.category ===
                CategoryEnum.CurrentScenario &&
                (this.props.state.scenarioScreen.scenarioType ===
                  ScenarioTypeEnum.BicycleOrientedDevelopment ||
                  this.props.state.scenarioScreen.scenarioType ===
                  ScenarioTypeEnum.OriginDestination)
              ) {
                return (
                  <>
                    <li>
                      <MainButton
                        onClick={() => {
                          this.props.stateHandler.scenarioRestartCalculation(
                            this.props.updateState
                          );
                        }}
                        icon={<TbReload size={12} />}
                        className={
                          this.props.state.scenarioScreen.calculationFinished
                            ? "MainBtn OK"
                            : "MainBtn disabled"
                        }
                      >
                        Bereken opnieuw
                      </MainButton>
                    </li>
                  </>
                );
              } else if (
                this.props.state.scenarioScreen.category ===
                CategoryEnum.NewScenario &&
                this.props.state.scenarioScreen.scenarioType ===
                ScenarioTypeEnum.BicycleOrientedDevelopment
              ) {
                return (
                  <>
                    <li>
                      <MainButton
                        onClick={() => {
                          this.props.stateHandler.changeScreen(
                            ScreenState.EditNetworkScreen,
                            this.props.updateState
                          );
                        }}
                        icon={<BiNetworkChart size={12} />}
                        className="MainBtn OK"
                      >
                        Netwerk bewerken
                      </MainButton>
                    </li>
                    <li>
                      <MainButton
                        onClick={() => {
                          this.props.stateHandler.changeScreen(
                            ScreenState.EditHousingPlanAreasScreen,
                            this.props.updateState
                          );
                        }}
                        icon={<BiSolidHomeAlt2 size={12} />}
                        className="MainBtn OK"
                      >
                        Woongebieden bewerken
                      </MainButton>
                    </li>
                    <li>
                      <MainButton
                        onClick={() => {
                          this.props.stateHandler.scenarioRestartCalculation(
                            this.props.updateState
                          );
                        }}
                        icon={<TbReload size={12} />}
                        className={
                          this.props.state.scenarioScreen.calculationFinished
                            ? "MainBtn OK"
                            : "MainBtn disabled"
                        }
                      >
                        Bereken opnieuw
                      </MainButton>
                    </li>
                    <li>
                      <MainButton
                        onClick={() => {
                          this.props.stateHandler.deleteScenario(
                            this.props.updateState
                          );
                        }}
                        icon={<GoXCircleFill size={12} />}
                        className="MainBtn Danger"
                      >
                        Scenario verwijderen
                      </MainButton>
                    </li>
                  </>
                );
              } else if (
                this.props.state.scenarioScreen.category ===
                CategoryEnum.NewScenario &&
                this.props.state.scenarioScreen.scenarioType ===
                ScenarioTypeEnum.OriginDestination
              ) {
                return (
                  <>
                    <li>
                      <MainButton
                        onClick={() => {
                          this.props.stateHandler.changeScreen(
                            ScreenState.EditNetworkScreen,
                            this.props.updateState
                          );
                        }}
                        icon={<BiNetworkChart size={12} />}
                        className="MainBtn OK"
                      >
                        Netwerk bewerken
                      </MainButton>
                    </li>
                    <li>
                      <MainButton
                        onClick={() => {
                          this.props.stateHandler.scenarioRestartCalculation(
                            this.props.updateState
                          );
                        }}
                        icon={<TbReload size={12} />}
                        className={
                          this.props.state.scenarioScreen.calculationFinished
                            ? "MainBtn OK"
                            : "MainBtn disabled"
                        }
                      >
                        Bereken opnieuw
                      </MainButton>
                    </li>
                    <li>
                      <MainButton
                        onClick={() => {
                          this.props.stateHandler.deleteScenario(
                            this.props.updateState
                          );
                        }}
                        icon={<GoXCircleFill size={12} />}
                        className="MainBtn Danger"
                      >
                        Scenario verwijderen
                      </MainButton>
                    </li>
                  </>
                );
              }
            })()}
          </ul>
        </div>
        <div id="ScenarioResultScreen-ContentHolder">
          <SectionTitle></SectionTitle>

          <div id="ScenarioResultScreen-ResultHolder">
            {this.props.state.scenarioScreen.resultBlocks
              .sort((a, b) => (a.order || 0) - (b.order || 0))
              .map((resultBlock) => {
                return (
                  <div
                    key={resultBlock.id}
                    className="ScenarioResultScreen-ResultItem"
                  >
                    <ResultItem
                      state={this.props.state}
                      resultBlock={resultBlock}
                    />
                    <ResultItemSideBar
                      state={this.props.state}
                      stateHandler={this.props.stateHandler}
                      updateState={(newState) => {
                        this.props.updateState(newState);
                      }}
                      resultBlock={resultBlock}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}
