import React from "react";
import "./EnlargedComponentPopup.css";
import Table from "./Table";
import { AppStateType } from "src/Types";
import Button from "./Button";
import { MdClose } from "react-icons/md";

interface EnlargedComponentPopupProps {
  state: AppStateType;
  onClose: () => void;
  component: any;
}

export default class EnlargedComponentPopup extends React.Component<EnlargedComponentPopupProps> {
  render() {
    return (
      <div id="EnlargedComponentPopup">
        <div id="EnlargedComponentPopup-Section">
          <div id="EnlargedComponentPopup-ComponentContainer">
            <div
              id="EnlargedComponentPopup-Component"
            >
              {this.props.component }
            </div>
            <Table Table={this.props.state.Legend} />
          </div>
          <div id="EnlargedComponentPopup-CloseButton">
            <Button onClick={this.props.onClose}>
              <MdClose size={20} />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
