import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import Button from "../../components/Button";
import { AppStateType } from "../../Types";
import "./LoginScreen.css";

import logo from "../../images/logo4.svg"
import InfoMessagePopup from "src/components/InfoMessagePopup";

interface LoginScreenProps {
	state: AppStateType;
	stateHandler: AppStateHandler;
	updateState: (newState: AppStateType) => void;
}

export default class LoginScreen extends React.Component<LoginScreenProps> {
	render() {
		return (
			<div id={"LoginScreen"}>
				<div id={"LoginScreen-Holder"}>
					<img id={"LoginScreen-Logo"} src={logo} alt="Logo" />

					{this.props.state.auth.errorMessage && (
						<div className="LoginScreen-ErrorMessage">
							{this.props.state.auth.errorMessage}
						</div>
					)}

					<input
						className={"LoginScreen-Input"}
						type="email"
						name="email"
						onChange={(event) => {
							this.props.stateHandler.loginFormEmailChange(
								event.target.value,
								this.props.updateState
							);
						}}
						placeholder="E-mailadres"
					/>
					<input
						className={"LoginScreen-Input"}
						type="password"
						name="password"
						onChange={(event) => {
							this.props.stateHandler.loginFormPasswordChange(
								event.target.value,
								this.props.updateState
							);
						}}
						placeholder="Wachtwoord"
					/>
					<Button
						onClick={() => {
							this.props.stateHandler.login(this.props.updateState);
						}}
					>
						Login
					</Button>

					<div id={"LoginScreen-MoreInfo"}>
						Voor meer informatie, neem contact op via{" "}
						<a id={"LoginScreen-Link"} href="mailto:info@stratopo.nl">
							info@stratopo.nl
						</a>
					</div>
				</div>
			</div>
		);
	}
}
