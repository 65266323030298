import React from "react";
import MenuTitle from "src/components/MenuTitle";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType } from "../../Types";
import "./ContactScreen.css";

interface ContactScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class ContactScreen extends React.Component<ContactScreenProps> {
  render() {
    return (
      <div id="ContactScreen">
        <div className="top-bar">
          <ul>
            <li>
              <MenuTitle title="Flowbiliteit | Contact" />
            </li>
          </ul>
        </div>

        <div id="ContactScreen-ContentHolder">
          <div id="ContactPlanner-Container">
            <p>
              Wil je kennismaken, plan dan vrijblijvend een (online) bespreking
              in.
            </p>
            <iframe
              title="contact"
              className="contact-planner"
              src="https://outlook.office365.com/book/StraTopo@stratopo.nl/"
              data-src="https://outlook.office365.com/book/StraTopo@stratopo.nl/"
              scrolling="yes"
              data-ll-status="loaded"
            ></iframe>
          </div>
          <div id="ContactPersons-Container">
            <div>
              <img
                className="contact-person-img"
                decoding="async"
                src="https://stratopo.nl/wp-content/uploads/2023/12/StraTopo_Sven_Reulen.png"
                data-src="https://stratopo.nl/wp-content/uploads/2023/12/StraTopo_Sven_Reulen.png"
                alt=""
                loading="lazy"
                data-ll-status="loaded"
              ></img>
              <h2>Sven Reulen</h2>
              <p>Oprichter</p>
            </div>
            <div>
              <img
                className="contact-person-img"
                decoding="async"
                src="https://stratopo.nl/wp-content/uploads/elementor/thumbs/67135198_454761885078295_6570340578855223296_n-e1737970585733-r0m96o5juttdxwlwaovrd2zdncmxhdayxngxvzgksk.png"
                data-src="https://stratopo.nl/wp-content/uploads/elementor/thumbs/67135198_454761885078295_6570340578855223296_n-e1737970585733-r0m96o5juttdxwlwaovrd2zdncmxhdayxngxvzgksk.png"
                title="67135198_454761885078295_6570340578855223296_n"
                alt="67135198_454761885078295_6570340578855223296_n"
                loading="lazy"
                data-ll-status="loaded"
              ></img>
              <h2>Ross Hudson</h2>
              <p>Geoconsultant</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
