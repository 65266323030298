import React from "react";
import "./MenuTitle.css";
import { MdOutlineFilterAlt, MdOutlineSort } from "react-icons/md";
import { ProjectFilteringOptions, ProjectSortingOptions } from "src/Types";
import { ProjectFilteringOptionsTranslation } from "src/translations";

interface MenuTitleProps {
  children?: React.ReactNode;
  title?: string;
  button?: boolean;
  button_content?: any;
  onClick?: () => void;
  icon?: any;
  sortingButton?: boolean;
  filteringButton?: boolean;
  sortingOption?: ProjectSortingOptions;
  filteringOption?: ProjectFilteringOptions;
  onSortingOptionSelect?: (option: ProjectSortingOptions) => void;
  onFilteringOptionSelect?: (option: ProjectFilteringOptions) => void;
  transparentButtonBackground?: boolean;
  no_margin?: boolean;
}

interface MenuTitleState {
  isSortDropdownVisible: boolean;
  isFilterDropdownVisible: boolean;
}

export default class MenuTitle extends React.Component<
  MenuTitleProps,
  MenuTitleState
> {
  private sortDropdownRef: React.RefObject<HTMLUListElement>;
  private filterDropdownRef: React.RefObject<HTMLUListElement>;
  private sortButtonRef: React.RefObject<HTMLButtonElement>;
  private filterButtonRef: React.RefObject<HTMLButtonElement>;

  constructor(props: MenuTitleProps) {
    super(props);

    this.sortDropdownRef = React.createRef();
    this.filterDropdownRef = React.createRef();
    this.sortButtonRef = React.createRef();
    this.filterButtonRef = React.createRef();

    this.state = {
      isSortDropdownVisible: false,
      isFilterDropdownVisible: false,
    };
  }

  handleClickOutside = (event: MouseEvent) => {
    if (
      this.sortDropdownRef.current &&
      this.sortButtonRef.current &&
      !this.sortDropdownRef.current.contains(event.target as Node) &&
      !this.sortButtonRef.current.contains(event.target as Node)
    ) {
      this.setState({ isSortDropdownVisible: false });
    }

    if (
      this.filterDropdownRef.current &&
      this.filterButtonRef.current &&
      !this.filterDropdownRef.current.contains(event.target as Node) &&
      !this.filterButtonRef.current.contains(event.target as Node)
    ) {
      this.setState({ isFilterDropdownVisible: false });
    }
  };

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  toggleSortDropdown = () => {
    this.setState((prevState) => ({
      isSortDropdownVisible: !prevState.isSortDropdownVisible,
      isFilterDropdownVisible: false,
    }));
  };

  toggleFilterDropdown = () => {
    this.setState((prevState) => ({
      isSortDropdownVisible: false,
      isFilterDropdownVisible: !prevState.isFilterDropdownVisible,
    }));
  };

  render() {
    const style = {
      marginTop: this.props.no_margin ? "0px" : "10px",
    };

    return (
      <div className="MenuTitle" style={style}>
        {" "}
        <div className="TitleContainer">
          <p className="Title">
            {this.props.icon} {this.props.title}
          </p>
          {this.props.button ? (
            <button
              className={
                this.props.transparentButtonBackground
                  ? "TitleBtn transparent"
                  : "TitleBtn"
              }
              onClick={() => {
                if (this.props.onClick !== undefined) {
                  this.props.onClick();
                }
              }}
            >
              {this.props.button_content}
            </button>
          ) : null}
        </div>
        {this.props.sortingButton || this.props.filteringButton ? (
          <div className="TitleActions">
            {this.props.sortingButton ? (
              <>
                <button
                  className="SortBtn"
                  ref={this.sortButtonRef}
                  onClick={this.toggleSortDropdown}
                >
                  <MdOutlineSort />
                </button>
                {this.state.isSortDropdownVisible && (
                  <ul className="DropdownList" ref={this.sortDropdownRef}>
                    <li
                      className={`DropdownItem ${
                        this.props.sortingOption ===
                        ProjectSortingOptions.alphabetically
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => {
                        if (this.props.onSortingOptionSelect) {
                          this.props.onSortingOptionSelect(
                            ProjectSortingOptions.alphabetically
                          );
                          this.toggleSortDropdown();
                        }
                      }}
                    >
                      A-Z
                    </li>
                    <li
                      className={`DropdownItem ${
                        this.props.sortingOption ===
                        ProjectSortingOptions.lastUpdated
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => {
                        if (this.props.onSortingOptionSelect) {
                          this.props.onSortingOptionSelect(
                            ProjectSortingOptions.lastUpdated
                          );
                          this.toggleSortDropdown();
                        }
                      }}
                    >
                      Last Updated
                    </li>
                  </ul>
                )}
              </>
            ) : null}
            {this.props.filteringButton ? (
              <>
                <button
                  className="FilterBtn"
                  ref={this.filterButtonRef}
                  onClick={this.toggleFilterDropdown}
                >
                  <MdOutlineFilterAlt />
                </button>
                {this.state.isFilterDropdownVisible && (
                  <ul className="DropdownList" ref={this.filterDropdownRef}>
                    {Object.values(ProjectFilteringOptions).map((option) => (
                      <li
                        key={option}
                        className={`DropdownItem ${
                          this.props.filteringOption === option ? "selected" : ""} `}
                        onClick={() => {
                          if (this.props.onFilteringOptionSelect) {
                            this.props.onFilteringOptionSelect(option);
                            this.toggleFilterDropdown();
                          }
                        }}
                      >
                        {ProjectFilteringOptionsTranslation[option]}
                      </li>
                    ))}
                  </ul>
                )}
              </>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}
