import React from "react";
import { FaUsersCog } from "react-icons/fa";
import { TbCheck } from "react-icons/tb";
import MainButton from "src/components/MainButton";
import MenuTitle from "src/components/MenuTitle";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import SectionTitle from "../../components/SectionTitle";
import "./AdminScreen.css";

interface AdminScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class AdminScreen extends React.Component<AdminScreenProps> {
  render() {
    return (
      <>
        <div id="AdminScreen">
          <div className="top-bar">
            <ul>
              <li>
                <MenuTitle title="Flowbiliteit | Admin" />
              </li>
              {(() => {
                if (this.props.state.listLogs.length === 0) {
                  return (
                    <li>
                      <div className="spinner">
                        <div className="pin"></div>
                      </div>
                    </li>
                  );
                } else {
                  return (
                    <li>
                      <div className="upToDate">
                        <TbCheck />
                      </div>
                    </li>
                  );
                }
              })()}
              <li>
                <MainButton
                  onClick={() => {
                    this.props.stateHandler.changeScreen(
                      ScreenState.UsersScreen,
                      this.props.updateState
                    );
                  }}
                  icon={<FaUsersCog size={12} />}
                  className="MainBtn"
                >
                  Gebruikers
                </MainButton>
              </li>
            </ul>
          </div>
          <div id="AdminScreen-ContentHolder">
            <SectionTitle subtitle={true}>Logs</SectionTitle>
            <table className="Logs-table">
              <thead>
                <tr>
                  <th>Datum</th>
                  <th>Tijd</th>
                  <th>Gebruiker</th>
                  <th>Actie</th>
                </tr>
              </thead>
              <tbody>
                {this.props.state.listLogs.map((log) => (
                  <tr>
                    <td>
                      {log.timestamp.toLocaleString("nl", {
                        year: "2-digit",
                        month: "short",
                        day: "2-digit",
                      })}
                    </td>
                    <td>
                      {log.timestamp.toLocaleString("nl", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      })}
                    </td>
                    <td>{log.user.split("@")[0]}</td>
                    <td>{log.logMessage}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}
