import L, { FitBoundsOptions, LatLng, PointExpression } from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useEffect } from "react";
import {
  CircleMarker,
  FeatureGroup,
  MapContainer,
  TileLayer,
  useMap,
} from "react-leaflet";
import "./ViewCustomLayers.css";

interface ViewCustomLayersProps {
  points: LatLng[];
}

export default class ViewCustomLayers extends React.Component<ViewCustomLayersProps> {
  center: LatLng = new LatLng(52.3676, 4.9041); // Amsterdam, The Netherlands

  minZoom: number = 0;
  maxZoom: number = 18;
  initialZoom: number = 13;

  FitBounds = () => {
    const map = useMap();
    const points = this.props.points;

    useEffect(() => {
      const bounds = L.latLngBounds(
        this.props.points.map((point) => [point.lat, point.lng])
      );
      const fitBoundsOptions: FitBoundsOptions = {
        padding: [50, 50] as PointExpression,
      };
      if (this.props.points.length === 0) {
        map.setView(this.center, this.initialZoom);
      } else {
        map.fitBounds(bounds, fitBoundsOptions);
      }
    }, [map, points]);

    return null;
  };

  Points = (): JSX.Element => {
    // const map = useMap();

    // Zoom to layer
    this.FitBounds();

    // Draw on the map
    return (
      <>
        <FeatureGroup>
          {this.props.points.map((point, index) => {
            return (
              <CircleMarker
                key={index}
                center={point}
                radius={3}
                weight={5}
                fillColor={"#2b83ba"}
                opacity={1}
                fillOpacity={1}
              />
            );
          })}
        </FeatureGroup>
      </>
    );
  };

  render() {
    return (
      <MapContainer
        center={this.center}
        zoom={this.initialZoom}
        maxZoom={this.maxZoom}
        minZoom={this.minZoom}
        zoomControl={false}
        attributionControl={true}
        id={"ViewCustomLayers"}
      >
        <TileLayer url="http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" />
        <this.Points />
      </MapContainer>
    );
  }
}
