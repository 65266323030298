import React from "react";
import "./InfoMessagePopup.css";
import Table from "./Table";

interface InfoMessagePopupProps {
  message?: string;
  massageTitle: string;
  button?: any;
  Table?: { color: string; label: string }[];
}

export default class InfoMessagePopup extends React.Component<InfoMessagePopupProps> {
  render() {
    return (
      <div id="MessagePopup">
        <div id="MessagePopup-Section">
          {/* Display the title */}
          <div id="MessagePopup-Title">{this.props.massageTitle}</div>
          <Table Table={this.props.Table} />

          {/* Display the message if it exists */}
          {this.props.message ? (
            <div id="MessagePopup-Text">{this.props.message}</div>
          ) : (
            <></> // Display nothing if the message is not defined
          )}

          {/* Display the buttons */}
          <div id="MessagePopup-Buttons">{this.props.button}</div>
        </div>
      </div>
    );
  }
}
