import React from "react";
import "./Table.css";

interface TableProps {
  Table: { color: string; label: string }[] | undefined;
}

export default class Table extends React.Component<TableProps> {
  render() {
    // Parse the table data if it is a string
    let parsedTable = this.props.Table;
    if (parsedTable !== undefined && !Array.isArray(parsedTable)) {
      parsedTable = JSON.parse(parsedTable as string);
    }

    return parsedTable && Array.isArray(parsedTable) && parsedTable.length > 0 ? (
      <div>
        <table id="Table">
          <tbody>
            {/* Map over the table data and display each row */}
            {parsedTable.map((row) => (
              <tr key={row.label}>
                <td id="Table-td">{row.label}</td>
                <td id="Table-td">
                  {/* Display a colored div for each row */}
                  <div
                    style={{
                      width: "50px",
                      height: "20px",
                      backgroundColor: row.color,
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <></> // Display nothing if the table is not an array
    );
  }
}
