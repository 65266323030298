import React from "react";
import "./ResultItem.css";
import { ContentTypeEnum, ResultBlock } from "src/client/lib";
import { ResultBlockMap } from "src/components/maps/ResultBlockMap";
import { ResultBlockOverviewMap } from "src/components/maps/ResultBlockOverviewMap";
import MapResultBlockHeatmap from "src/components/maps/MapResultBlockHeatmap";
import MapOriginDestinationNetwork from "src/components/maps/MapOriginDestinationNetwork";
import MapOriginDestinationNetworkDiff from "src/components/maps/MapOriginDestinationNetworkDiff";
import BODGraph from "src/components/graphs/BODGraph";
import BODGraphDiff from "src/components/graphs/BODGraphDiff";
import MunicipalityGraph from "src/components/graphs/MunicipalityGraph";
import MunicipalityGraphDiff from "src/components/graphs/MunicipalityGraphDiff";
import HousingPlanGraph from "src/components/graphs/HousingPlanGraph";
import { AppStateType } from "src/Types";

interface ResultItemProps {
  state: AppStateType;
  resultBlock: ResultBlock;
}

export default class ResultItem extends React.Component<ResultItemProps> {
  render() {
    const resultBlock = this.props.resultBlock;

    return (
      <>
        <div className="ScenarioResultScreen-ResultItemTitle">
          {resultBlock.title}
        </div>

        {(() => {
          if (
            resultBlock.contentType &&
            (resultBlock.contentType === ContentTypeEnum.Isochrone ||
              resultBlock.contentType === ContentTypeEnum.ScenarioChanges)
          ) {
            return (
              <ResultBlockMap
                geoJsonString={resultBlock.content}
                mapType={resultBlock.contentType}
              ></ResultBlockMap>
            );
          } else if (
            resultBlock.contentType &&
            resultBlock.contentType === ContentTypeEnum.Overview
          ) {
            return (
              <ResultBlockOverviewMap
                geoJsonString={resultBlock.content}
                mapType={resultBlock.contentType}
              ></ResultBlockOverviewMap>
            );
          } else if (
            resultBlock.contentType &&
            (resultBlock.contentType === ContentTypeEnum.Heatmap ||
              resultBlock.contentType === ContentTypeEnum.HeatmapDiff)
          ) {
            return (
              <MapResultBlockHeatmap
                geoJsonString={resultBlock.content}
                mapType={resultBlock.contentType}
                overviewGeoJsonString={
                  this.props.state.scenarioScreen.resultBlocks[0].content
                }
              ></MapResultBlockHeatmap>
            );
          } else if (
            resultBlock.contentType &&
            resultBlock.contentType === ContentTypeEnum.OriginDestinationNetwork
          ) {
            return (
              <MapOriginDestinationNetwork
                geoJsonString={resultBlock.content}
              ></MapOriginDestinationNetwork>
            );
          } else if (
            resultBlock.contentType &&
            resultBlock.contentType ===
              ContentTypeEnum.OriginDestinationNetworkDiff
          ) {
            return (
              <MapOriginDestinationNetworkDiff
                geoJsonString={resultBlock.content}
              ></MapOriginDestinationNetworkDiff>
            );
          } else if (
            resultBlock.contentType &&
            resultBlock.contentType === ContentTypeEnum.Graph
          ) {
            return <BODGraph dataString={resultBlock.content}></BODGraph>;
          } else if (
            resultBlock.contentType &&
            resultBlock.contentType === ContentTypeEnum.GraphDiff
          ) {
            return (
              <BODGraphDiff dataString={resultBlock.content}></BODGraphDiff>
            );
          } else if (
            resultBlock.contentType &&
            resultBlock.contentType === ContentTypeEnum.MunicipalityGraph
          ) {
            return (
              <MunicipalityGraph
                dataString={resultBlock.content}
              ></MunicipalityGraph>
            );
          } else if (
            resultBlock.contentType &&
            resultBlock.contentType === ContentTypeEnum.MunicipalityGraphDiff
          ) {
            return (
              <MunicipalityGraphDiff
                dataString={resultBlock.content}
              ></MunicipalityGraphDiff>
            );
          } else if (
            resultBlock.contentType &&
            resultBlock.contentType === ContentTypeEnum.HousingPlanGraph
          ) {
            return (
              <HousingPlanGraph
                dataString={resultBlock.content}
              ></HousingPlanGraph>
            );
          }
        })()}
      </>
    );
  }
}
