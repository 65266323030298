import { AppStateHandler } from "../AppStateHandler";
import { AppStateType, ScreenState } from "../Types";

export class ProjectBarStateHandler {
  loadProjects(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void,
    select_first_scenario?: boolean
  ) {
    if (select_first_scenario === undefined) {
      select_first_scenario = true;
    }
    this.projectApi
      .apiProjectList()
      .then((response) => {
        this.state.projects = response;

        this.sortProjects(updateState);

        if (select_first_scenario) {
          // Select first project and first scenario
          if (this.state.projects.length > 0) {
            this.state.selectedProject = this.state.projects[0].id;
            if (this.state.projects[0].scenarioList.length > 0) {
              this.state.selectedScenario =
                this.state.projects[0].scenarioList[0].id;
            }
          }
        }

        updateState(this.state);
      })
      .catch((error) => {
        console.log("Error while loading projects", error);
      });
  }

  changeSelectedProject(
    this: AppStateHandler,
    selectedProject: number,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.selectedProject = selectedProject;
    this.state.selectedScenario = null;
    updateState(this.state);
    this.changeScreen(ScreenState.Project, updateState);
  }

  changeSelectedScenario(
    this: AppStateHandler,
    selectedProject: number,
    selectedScenario: number,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.selectedProject = selectedProject;
    this.state.selectedScenario = selectedScenario;
    updateState(this.state);
    this.changeScreen(ScreenState.ScenarioResult, updateState);
  }

  projectBarCreateNewScenario(
    this: AppStateHandler,
    forProject: number,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.selectedProject = forProject;
    this.state.selectedScenario = null;
    updateState(this.state);
    this.changeScreen(ScreenState.NewScenario, updateState);
  }

  editScenario(
    this: AppStateHandler,
    newName: string,
    updateState: (newState: AppStateType) => void
  ) {
    if (newName !== undefined) {
      this.state.editScenario.name = `${newName}`;
    }
    updateState(this.state);
  }

  updatePartialScenario(
    this: AppStateHandler,
    newName: string,
    updateState: (newState: AppStateType) => void
  ) {
    if (this.state.editScenario.id) {
      const patchedScenarioUpdateRequest = {
        name: newName,
      };

      this.scenarioApi
        .apiScenarioPartialUpdate({
          id: this.state.editScenario.id as number,
          patchedScenarioUpdateRequest,
        })
        .then((response) => {
          const updatedProjects = this.state.projects.map((project) => {
            if (project.id !== this.state.editScenario.projectId) {
              return project;
            }

            return {
              ...project,
              scenarioList: project.scenarioList.map((scenario) => {
                return scenario.id === this.state.editScenario.id
                  ? { ...scenario, name: newName }
                  : scenario
              }
              ),
            };

          });

          if (this.state.selectedScenario === this.state.editScenario.id) {
            this.state.scenarioScreen.name = newName;
          }

          this.state.projects = updatedProjects;
          this.state.editScenario.id = null;
          this.state.editScenario.name = "";
          this.state.editScenario.editScenarioPopUpVisible = false;

          updateState(
            this.state
          );
        })
        .catch((error) => {
          console.log("Error while updating scenario name", error);
        });
    }
  }

}
