import React from "react";
import Button from "./Button";
import "./EditScenarioPopUp.css";
import { TextInput, TextInputType } from "./TextInput";
import { MdClose } from "react-icons/md";
import { AppStateType } from "src/Types";
import { AppStateHandler } from "src/AppStateHandler";

interface EditScenarioPopUpProps {
  changeName: string | null;
  onSave: () => void;
  onClose: () => void;
  updateName: (
    newName: string
  ) => void;
}

export default class EditScenarioPopUp extends React.Component<EditScenarioPopUpProps> {

  render() {
    return (
      <div id="EditScenarioPopUp">
        <div id="EditScenarioPopUp_Box">
          <div id="EditScenarioPopUp_ContentWrapper">
            <div id="EditScenarioPopUp_Title">Verander de naam van het scenario</div>

            <div id="EditScenarioPopUp_Section">
              <div className="EditScenarioPopUp_Tag">Scenarionaam</div>
              <TextInput
                type={TextInputType.TextSpacesNumbersLines}
                value={`${this.props.changeName}`}
                onChange={(newName) => {
                  this.props.updateName(newName);
                }}
                placeholderColor={"#BBB"}
                placeholder={"Name"}
              ></TextInput>
            </div>

            <div id="EditScenarioPopUp_SubmitButtonHolder">
              <Button
                onClick={this.props.onSave}
              >
                Opslaan
              </Button>
            </div>

            <div id="EditScenarioPopUp-CloseButton">
              <Button onClick={this.props.onClose}>
                <MdClose size={20} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
