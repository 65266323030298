/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 25.03.24.09.38
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CategoryEnum } from './CategoryEnum';
import {
    CategoryEnumFromJSON,
    CategoryEnumFromJSONTyped,
    CategoryEnumToJSON,
} from './CategoryEnum';

/**
 * 
 * @export
 * @interface ProjectListScenarioResponse
 */
export interface ProjectListScenarioResponse {
    /**
     * 
     * @type {number}
     * @memberof ProjectListScenarioResponse
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectListScenarioResponse
     */
    name: string;
    /**
     * 
     * @type {CategoryEnum}
     * @memberof ProjectListScenarioResponse
     */
    category?: CategoryEnum;
    /**
     * 
     * @type {Date}
     * @memberof ProjectListScenarioResponse
     */
    readonly timestampLastModified: Date;
}

/**
 * Check if a given object implements the ProjectListScenarioResponse interface.
 */
export function instanceOfProjectListScenarioResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "timestampLastModified" in value;

    return isInstance;
}

export function ProjectListScenarioResponseFromJSON(json: any): ProjectListScenarioResponse {
    return ProjectListScenarioResponseFromJSONTyped(json, false);
}

export function ProjectListScenarioResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectListScenarioResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'category': !exists(json, 'category') ? undefined : CategoryEnumFromJSON(json['category']),
        'timestampLastModified': (new Date(json['timestamp_last_modified'])),
    };
}

export function ProjectListScenarioResponseToJSON(value?: ProjectListScenarioResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'category': CategoryEnumToJSON(value.category),
    };
}

