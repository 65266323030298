/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 25.03.24.09.38
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CategoryEnum } from './CategoryEnum';
import {
    CategoryEnumFromJSON,
    CategoryEnumFromJSONTyped,
    CategoryEnumToJSON,
} from './CategoryEnum';
import type { ResultBlock } from './ResultBlock';
import {
    ResultBlockFromJSON,
    ResultBlockFromJSONTyped,
    ResultBlockToJSON,
} from './ResultBlock';
import type { ScenarioTypeEnum } from './ScenarioTypeEnum';
import {
    ScenarioTypeEnumFromJSON,
    ScenarioTypeEnumFromJSONTyped,
    ScenarioTypeEnumToJSON,
} from './ScenarioTypeEnum';

/**
 * 
 * @export
 * @interface ScenarioInfoResponse
 */
export interface ScenarioInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof ScenarioInfoResponse
     */
    name: string;
    /**
     * 
     * @type {CategoryEnum}
     * @memberof ScenarioInfoResponse
     */
    category: CategoryEnum;
    /**
     * 
     * @type {ScenarioTypeEnum}
     * @memberof ScenarioInfoResponse
     */
    scenarioType: ScenarioTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ScenarioInfoResponse
     */
    calculationFinished: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScenarioInfoResponse
     */
    upToDate: boolean;
    /**
     * 
     * @type {Array<ResultBlock>}
     * @memberof ScenarioInfoResponse
     */
    resultBlockList: Array<ResultBlock>;
    /**
     * 
     * @type {number}
     * @memberof ScenarioInfoResponse
     */
    projectAreaCentroidLat: number;
    /**
     * 
     * @type {number}
     * @memberof ScenarioInfoResponse
     */
    projectAreaCentroidLon: number;
    /**
     * 
     * @type {number}
     * @memberof ScenarioInfoResponse
     */
    baseTopology: number;
}

/**
 * Check if a given object implements the ScenarioInfoResponse interface.
 */
export function instanceOfScenarioInfoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "scenarioType" in value;
    isInstance = isInstance && "calculationFinished" in value;
    isInstance = isInstance && "upToDate" in value;
    isInstance = isInstance && "resultBlockList" in value;
    isInstance = isInstance && "projectAreaCentroidLat" in value;
    isInstance = isInstance && "projectAreaCentroidLon" in value;
    isInstance = isInstance && "baseTopology" in value;

    return isInstance;
}

export function ScenarioInfoResponseFromJSON(json: any): ScenarioInfoResponse {
    return ScenarioInfoResponseFromJSONTyped(json, false);
}

export function ScenarioInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScenarioInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'category': CategoryEnumFromJSON(json['category']),
        'scenarioType': ScenarioTypeEnumFromJSON(json['scenario_type']),
        'calculationFinished': json['calculation_finished'],
        'upToDate': json['up_to_date'],
        'resultBlockList': ((json['result_block_list'] as Array<any>).map(ResultBlockFromJSON)),
        'projectAreaCentroidLat': json['project_area_centroid_lat'],
        'projectAreaCentroidLon': json['project_area_centroid_lon'],
        'baseTopology': json['base_topology'],
    };
}

export function ScenarioInfoResponseToJSON(value?: ScenarioInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'category': CategoryEnumToJSON(value.category),
        'scenario_type': ScenarioTypeEnumToJSON(value.scenarioType),
        'calculation_finished': value.calculationFinished,
        'up_to_date': value.upToDate,
        'result_block_list': ((value.resultBlockList as Array<any>).map(ResultBlockToJSON)),
        'project_area_centroid_lat': value.projectAreaCentroidLat,
        'project_area_centroid_lon': value.projectAreaCentroidLon,
        'base_topology': value.baseTopology,
    };
}

