/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 25.03.24.09.38
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RoadCreateUpdateDestroyResponse,
  RoadCreateUpdateRequest,
  RoadListResponse,
} from '../models';
import {
    RoadCreateUpdateDestroyResponseFromJSON,
    RoadCreateUpdateDestroyResponseToJSON,
    RoadCreateUpdateRequestFromJSON,
    RoadCreateUpdateRequestToJSON,
    RoadListResponseFromJSON,
    RoadListResponseToJSON,
} from '../models';

export interface ApiRoadCreateRequest {
    roadCreateUpdateRequest: RoadCreateUpdateRequest;
}

export interface ApiRoadDestroyRequest {
    id: number;
}

export interface ApiRoadListRequest {
    scenario: number;
}

export interface ApiRoadUpdateRequest {
    id: number;
    roadCreateUpdateRequest: RoadCreateUpdateRequest;
}

/**
 * 
 */
export class RoadApi extends runtime.BaseAPI {

    /**
     * Endpoint for adding road
     */
    async apiRoadCreateRaw(requestParameters: ApiRoadCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoadCreateUpdateDestroyResponse>> {
        if (requestParameters.roadCreateUpdateRequest === null || requestParameters.roadCreateUpdateRequest === undefined) {
            throw new runtime.RequiredError('roadCreateUpdateRequest','Required parameter requestParameters.roadCreateUpdateRequest was null or undefined when calling apiRoadCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/road/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoadCreateUpdateRequestToJSON(requestParameters.roadCreateUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoadCreateUpdateDestroyResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for adding road
     */
    async apiRoadCreate(requestParameters: ApiRoadCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoadCreateUpdateDestroyResponse> {
        const response = await this.apiRoadCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for deleting edited roads
     */
    async apiRoadDestroyRaw(requestParameters: ApiRoadDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoadCreateUpdateDestroyResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRoadDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/road/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoadCreateUpdateDestroyResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for deleting edited roads
     */
    async apiRoadDestroy(requestParameters: ApiRoadDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoadCreateUpdateDestroyResponse> {
        const response = await this.apiRoadDestroyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for receiving a list of roads
     */
    async apiRoadListRaw(requestParameters: ApiRoadListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RoadListResponse>>> {
        if (requestParameters.scenario === null || requestParameters.scenario === undefined) {
            throw new runtime.RequiredError('scenario','Required parameter requestParameters.scenario was null or undefined when calling apiRoadList.');
        }

        const queryParameters: any = {};

        if (requestParameters.scenario !== undefined) {
            queryParameters['scenario'] = requestParameters.scenario;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/road/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoadListResponseFromJSON));
    }

    /**
     * Endpoint for receiving a list of roads
     */
    async apiRoadList(requestParameters: ApiRoadListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RoadListResponse>> {
        const response = await this.apiRoadListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for editing existing roads
     */
    async apiRoadUpdateRaw(requestParameters: ApiRoadUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoadCreateUpdateDestroyResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRoadUpdate.');
        }

        if (requestParameters.roadCreateUpdateRequest === null || requestParameters.roadCreateUpdateRequest === undefined) {
            throw new runtime.RequiredError('roadCreateUpdateRequest','Required parameter requestParameters.roadCreateUpdateRequest was null or undefined when calling apiRoadUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/road/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RoadCreateUpdateRequestToJSON(requestParameters.roadCreateUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoadCreateUpdateDestroyResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for editing existing roads
     */
    async apiRoadUpdate(requestParameters: ApiRoadUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoadCreateUpdateDestroyResponse> {
        const response = await this.apiRoadUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
