import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class LoginStateHandler {
  loginFormEmailChange(
    this: AppStateHandler,
    newEmail: string,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.auth.email = newEmail;
    updateState(this.state);
  }

  loginFormPasswordChange(
    this: AppStateHandler,
    newPassword: string,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.auth.password = newPassword;
    updateState(this.state);
  }

  login(this: AppStateHandler, updateState: (newState: AppStateType) => void) {
    if (this.state.auth.email !== null && this.state.auth.password !== null) {
      this.authApi
        .authLoginCreate({
          login: {
            email: this.state.auth.email,
            password: this.state.auth.password,
          },
        })
        .then((response) => {
          if (response.access !== null && response.refresh !== null) {
            this.setTokens(response.access, response.refresh, updateState);
            this.loadProjects(() => {
              const has_projects = this.state.projects.length > 0;
              if (has_projects) {
                this.changeScreen(ScreenState.ScenarioResult, () => {
                  this.current_user(updateState);
                });
              } else {
                this.changeScreen(ScreenState.NewProject, () => {
                  this.current_user(updateState);
                });
              }
            });
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.state.auth.errorMessage = "Ongeldig e-mailadres of wachtwoord. Probeer het opnieuw.";
          } else {
            this.state.auth.errorMessage = "Er is een fout opgetreden tijdens het inloggen. Probeer het opnieuw.";
          }

          updateState(this.state);

          console.error("Error while logging in", error);
        });
    }
  }
}
